<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <div class="vd_edhea vg_button_group">
      <el-row>
        <el-col :md="14">
          <el-button type="success"  size="small" disabled>编辑</el-button>
          <el-button type="primary" size="small" :class="switchMargin()" @click="buttonRefresh()" v-if="isShow">刷新</el-button>
        </el-col>
        <el-col :md="10" class="vd_export2">
          <div>
            <span style="margin-right:2px;">客户编号:</span>
            <el-input size="small" style="width:200px;" v-model="custForm.cust_no" disabled show-word-limit></el-input>
          </div>
          <span class="vg_tag_position">
            <span class="vg_tag_label">状态：</span>
            <el-tag :type="helper.getStatusName(custForm.status).type">{{helper.getStatusName(custForm.status).name}}</el-tag>
          </span>
        </el-col>
      </el-row>
    </div>
    <el-form ref="custForm" :model="custForm" :rules="rules" label-width="120px" size="mini" :disabled="isShow">
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="客户简称" prop="cust_abbr">
            <el-input maxlength="30" v-model="custForm.cust_abbr" placeholder="请填写客户简称" @input="custForm.cust_abbr = helper.keepEngNum3(custForm.cust_abbr)"
              show-word-limit>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="外销员所属部门" prop="cust_dept_id">
            <el-input maxlength="30" v-model="custForm.cust_dept_name" placeholder="请填写外销员所属部门" show-word-limit>
            </el-input>
            <!-- <el-select v-model="custForm.cust_dept_id" filterable>
              <el-option v-for="item in deptList" :key="item.dept_id" :value="item.dept_id" :label="item.dept_name">
              </el-option>
            </el-select> -->
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="指定外销员" prop="cust_stff_id">
            <el-input maxlength="30" v-model="custForm.cust_stff_name" placeholder="请填写指定外销员" show-word-limit>
            </el-input>
          </el-form-item>
        </el-col>

      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="客户属性" prop="cust_attr">
            <el-input maxlength="30" v-model="custForm.cust_attr" placeholder="请填写客户属性" show-word-limit>
            </el-input>
            <!-- <el-select @change="custAttrChange" v-model="custForm.cust_attr"  placeholder="请选择客户属性" size="small"
              >
              <el-option v-for="item in custAttrList" :key="item.id" :label="item.param1" :value="item.param1">
              </el-option>
            </el-select> -->
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="客户全称" prop="cust_name">
            <el-input maxlength="50" v-model="custForm.cust_name" placeholder="请填写客户全称"  show-word-limit>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="所属国家" prop="cust_country">
            <el-input maxlength="30" v-model="custForm.cust_country" placeholder="请填写所属国家" show-word-limit>
            </el-input>
            <!-- <el-select filterable v-model="custForm.cust_country" placeholder="请选择所属国家">
              <el-option v-for="item in custCountryList" :key="item.id" :label="item.param1" :value="item.param1">
              </el-option>
            </el-select> -->
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
    
        <el-col :md="8">
          <el-form-item label="客户邮箱" prop="cust_mail">
            <el-input maxlength="50" v-model="custForm.cust_mail"
              @input="custForm.cust_mail = helper.keepEngNum2(custForm.cust_mail)" placeholder="请填写客户邮箱"
              show-word-limit>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="公司抬头" prop="cptt_aid">
            <el-input maxlength="30" v-model="custForm.cptt_aname" placeholder="请填写公司抬头" show-word-limit>
            </el-input>
            <!-- <el-select filterable v-model="custForm.cptt_aid" placeholder="请选择公司抬头">
              <el-option v-for="item in cpttList" :key="item.cptt_id" :label="item.cptt_name" :value="item.cptt_id">
              </el-option>
            </el-select> -->
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="收款抬头" prop="cptt_bid">
            <el-input maxlength="30" v-model="custForm.cptt_bname" placeholder="请填写收款抬头" show-word-limit>
            </el-input>
            <!-- <el-select filterable v-model="custForm.cptt_bid" placeholder="请选择收款抬头">
              <el-option v-for="item in cpttList" :key="item.cptt_id" :label="item.cptt_name" :value="item.cptt_id">
              </el-option>
            </el-select> -->
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="联系人" prop="cust_contact">
            <el-input maxlength="20" v-model="custForm.cust_contact" placeholder="请填写联系人" show-word-limit>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="手机号" prop="cust_phone">
            <el-input maxlength="20" v-model="custForm.cust_phone"
              @input="custForm.cust_phone = helper.keepTNum(custForm.cust_phone)" placeholder="请填写手机号" show-word-limit>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="座机号" prop="cust_tel">
            <el-input maxlength="20" v-model="custForm.cust_tel"
              @input="custForm.cust_tel = helper.purephone(custForm.cust_tel)" placeholder="请填写座机号" show-word-limit>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="放款额度" prop="cust_loan">
            <el-input maxlength="11" v-model="custForm.cust_loan"
              @input="custForm.cust_loan = helper.keepTNum1(custForm.cust_loan)"
              @blur="custForm.cust_loan = helper.calcPriceT(custForm.cust_loan,2,100000000)" placeholder="请填写放款额度"
              show-word-limit>
              <template slot="append">$</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="客户账期" prop="cust_creday">
            <el-input maxlength="10" v-model="custForm.cust_creday"
              @input="custForm.cust_creday = helper.keepTNum(custForm.cust_creday)" placeholder="请填写客户账期"
              show-word-limit>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="付款方式" prop="cust_payway">
            <el-input maxlength="30" v-model="custForm.cust_payway" placeholder="请填写付款方式" show-word-limit>
            </el-input>
            <!-- <el-select filterable v-model="custForm.cust_payway" placeholder="请选择付款方式">
              <el-option v-for="item in custPaywayList" :key="item.id" :label="item.param1" :value="item.param1">
              </el-option>
            </el-select> -->
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="价格条款" prop="cust_paylaw">
            <el-input maxlength="30" v-model="custForm.cust_paylaw" placeholder="请填写价格条款" show-word-limit>
            </el-input>
            <!-- <el-select filterable v-model="custForm.cust_paylaw" placeholder="请选择价格条款">
              <el-option v-for="item in custPaylwayList" :key="item.id" :label="item.param1" :value="item.param1">
              </el-option>
            </el-select> -->
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="币种" prop="cust_currency">
            <el-input maxlength="30" v-model="custForm.cust_currency" placeholder="请填写币种" show-word-limit>
            </el-input>
            <!-- <el-select filterable v-model="custForm.cust_currency" placeholder="请选择币种">
              <el-option v-for="item in custCurrencyList" :key="item.id" :label="item.param1" :value="item.param1">
              </el-option>
            </el-select> -->
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="贸易方式" prop="cust_tradeway">
            <el-input maxlength="30" v-model="custForm.cust_tradeway" placeholder="请填写贸易方式" show-word-limit>
            </el-input>
            <!-- <el-select filterable v-model="custForm.cust_tradeway" placeholder="请选择贸易方式">
              <el-option v-for="item in custTradewayList" :key="item.id" :label="item.param1" :value="item.param1">
              </el-option>
            </el-select> -->
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="运输方式" prop="cust_shipway">
            <el-input maxlength="30" v-model="custForm.cust_shipway" placeholder="请填写运输方式" show-word-limit>
            </el-input>
            <!-- <el-select filterable v-model="custForm.cust_shipway" placeholder="请选择运输方式">
              <el-option v-for="item in custShipwayList" :key="item.id" :label="item.param1" :value="item.param1">
              </el-option>
            </el-select> -->
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="目的国" prop="cust_dcountry">
            <el-input maxlength="30" v-model="custForm.cust_dcountry" placeholder="请填写目的国" show-word-limit>
            </el-input>
            <!-- <el-select filterable v-model="custForm.cust_dcountry" placeholder="请选择目的国">
              <el-option v-for="item in custCountryList" :key="item.id" :label="item.param1" :value="item.param1">
              </el-option>
            </el-select> -->
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="目的港" prop="cust_dport">
            <el-input maxlength="30" v-model="custForm.cust_dport" placeholder="请填写目的港" show-word-limit>
            </el-input>
            <!-- <el-select filterable v-model="custForm.cust_dport" placeholder="请选择目的港">
              <el-option v-for="item in custDportList" :key="item.id" :label="item.param1" :value="item.param1">
              </el-option>
            </el-select> -->
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="货代简称" prop="inst_abbr">
            <el-input maxlength="10" v-model="custForm.inst_abbr" disabled placeholder="请填写货代简称" show-word-limit>
              <!-- <template slot="append">
                <el-link v-if="chooseInst" type="primary" class="vg_cursor_hander" @click="dialogVisible = true;">双击选择</el-link>
              </template> -->
            </el-input>
          </el-form-item>
        </el-col>
        <el-col v-if="custPidInputFlag" :md="8">
          <el-form-item label="所属中间商" :rules="[{ required: true }]" disabled>
            <el-input v-model="cust_pid_name" show-word-limit placeholder="请填写客户所属中间商" @click.native="custImport()" disabled> 
              <!-- <template slot="append">
                <el-link type="primary" :disabled="isShow" class="vg_cursor_hander">选择</el-link>
              </template> -->
              {{ cust_pid_name }}
            </el-input>
          </el-form-item>
          <el-dialog :title="custTitle" :visible.sync="CustPidFlag" width="70%">
            <custEditMiddleMan @cancelCust="cancelCust" @custPID="custPID" @custName="custName" @custCancel="custCancel"></custEditMiddleMan>
          </el-dialog>
        </el-col>
        <el-col :md="8">
          <el-form-item label="客户地址" prop="cust_addr">
            <el-input maxlength="255" v-model="custForm.cust_addr" type="textarea" :rows="1" show-word-limit
              placeholder="请填写备注">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-row class="vg_mb_5">
      </el-row> -->
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="客户验货标准" prop="cust_inspstand">
            <el-select filterable v-model="custForm.cust_inspstand" placeholder="请选择客户验货标准">
              <el-option v-for="item in custInspList" :key="item.id" :label="item.label" :value="item.label">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="16">
          <el-form-item label="唛头" prop="cust_shipmark">
            <el-input maxlength="255" v-model="custForm.cust_shipmark" type="textarea" :rows="3" show-word-limit
              placeholder="请填写备注">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm" ref="userData" v-if="custForm.stff_name" :typeFlag="1"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from "@api/request";
import { custAPI } from "@api/modules/cust";
import { cpttAPI } from "@api/modules/comptitle";
import { stffAPI } from "@/api/modules/staff";
import { optnAPI } from "@api/modules/optn";
import { openAPI } from "@api/modules/open";
import { deptAPI } from '@api/modules/department'
import inputUser from "@/views/component/inputUser";
import custEditMiddleMan from "./Componet/CustEditMiddleMan.vue";


export default {
  name: 'custEditMain',
  components: {
    inputUser,
    custEditMiddleMan,
  },
  data() {
    return {
      rules: {
        cust_abbr: [
          { required: true, trigger: 'blur', message: ' ' },
        ],
        cust_attr: [
          { required: true, trigger: 'blur', message: ' ' },
        ],
        cptt_aid: [
          { required: true, trigger: ['blur', 'change'], message: ' ' },
        ],
        cptt_bid: [
          { required: true, trigger: ['blur', 'change'], message: ' ' },
        ],
        cust_stff_id: [
          { required: true, trigger: ['blur', 'change'], message: ' ' },
        ],
        cust_name: [
          { required: true, trigger: 'blur', message: ' ' },
        ],
        cust_country: [
          { required: true, trigger: ['blur', 'change'], message: ' ' },
        ],
        cust_mail: [
          { required: true, trigger: ['blur'], message: ' ' },
        ],
        cust_tradeway: [
          { required: true, trigger: ['blur', 'change'], message: ' ' },
        ],
        cust_dept_id: [
          { required: true, trigger: ['blur', 'change'], message: ' ' },
        ],
        cust_contact: [
          { required: true, trigger: ['blur', 'change'], message: ' ' },
        ],
        cust_phone: [
          { required: true, trigger: ['blur', 'change'], message: ' ' },
        ],
      },
      custForm: {
        cust_no: null,
        cust_name: null,
        cust_country: null,
        cust_abbr: null,
        cust_stff_id: null,
        cust_attr: null,
        cust_attr_code: null,
        cust_addr: null,
        cust_contact: null,
        cust_phone: null,
        cust_tel: null,
        cust_loan: null,
        cust_creday: null,
        cust_payway: null,
        cust_paylaw: null,
        cust_currency: null,
        cust_mail: null,
        cust_tradeway: null,
        cust_shipway: null,
        cust_dcountry: null,
        cust_dport: null,
        cptt_aid: null,
        cptt_bid: null,
        inst_id: null,
        inst_abbr: null,
        cust_shipmark: null,
        cust_dept_id: null,
        cust_pid: null,
        cust_inspstand:null,
      },
      cust_pid_name:null, // 所属中间商姓名
      custPidInputFlag:false, // 是否显示所属中间商
      CustPidFlag:false, // 中间商选择弹窗
      custTitle:'添加所属中间商客户',
      isShow: true,
      btn: {},
      loadingFlag: true,
      stffForm: {},
      dialogVisible: false,
      stffList: [],
      custAttrList: [],
      custCountryList: [],
      custPaywayList: [],
      custPaylwayList: [],
      custCurrencyList: [],
      custTradewayList: [],
      custShipwayList: [],
      custDportList: [],
      cpttList: [],
      deptList: [],
      chooseInst: false, // 是否显示双击选择货代el-link
      custInspList:[
        {id:0,label:'0.65'},
        {id:1,label:'1.0'},
        {id:2,label:'1.5'},
        {id:3,label:'2.5'},
        {id:4,label:'4.0'},
        {id:5,label:'6.0'},
      ]
    }
  },
  created() {
    this.initData()
  },
  watch: {
  },
  methods: {
    initData() {
      this.loadingFlag = true
      this.getcustInfo();
      // this.getStffUser()
      // this.getCustAttrList()
      // this.getContry()
      // this.getCustPayway()
      // this.getCustPaylway()
      // this.getCustCurrency()
      // this.getCustTradeway()
      // this.getCustShipway()
      // this.getCustDport()
      // this.getCpttUser()
      // this.getDept()
    },
    getDept() {
      get(deptAPI.getAllDeptsV1).then(res => {
        if (res.data.code === 0) {
          this.deptList = res.data.data
          return
        }
        this.$message.error(res.data.msg)
      }).catch(res => {
        this.$message.error(res.data.msg)
      })
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.chooseInst = true;
      this.$emit('isShow', this.isShow)
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.isShow = true;
        this.$emit('isShow', this.isShow)
        this.chooseInst = false;
        this.initData()
        this.$message({
          type: 'info',
          message: '已撤销编辑!'
        });
      }).catch(() => { })
    },
    //关闭选择中间商弹窗
    custCancel(){
      this.CustPidFlag = false
    },
     //打开选择中间商弹窗
    custImport(){
      if(!this.isShow){
        if(this.custForm.cust_attr === '最终客户'){
          this.CustPidFlag = true;
        }
      }
    },
    cancelCust(){
      this.CustPidFlag = false
    },
     // custPID子传父值
    custPID(val){
      if(val){
        this.custForm.cust_pid=val;
      }else{
        this.custForm.cust_pid=null;
      }
    },
     // custIname子传父值
     custName(val){
      if(val){
        this.cust_pid_name=val;
      }else{
        this.cust_pid_name=null;
      }
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saveInfo()
          } else {
            console.log('error submit!');
            this.$message.warning('请把必填内容补充完整')
            return false;
          }
        });
      }).catch(() => { })
    },
    // 保存
    saveInfo() {
      const props = JSON.parse(this.$UrlEncode.decode(this.$route.query.key))
      this.custForm.cust_id = props.form_id
      this.custForm.personal = this.$cookies.get('userInfo').personal
      post(custAPI.editCust, this.custForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            })
            this.isShow = true;
            this.$emit('isShow', this.isShow)
            this.initData()
            this.chooseInst = false;
          } else if (res.data.code === 7) {
            this.$message({
              type: 'error',
              message: '客户编号已存在'
            })
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData()
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        })
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData()
    },
    // 生成fcp账号
    synchronization() {
      post(openAPI.createFcpAccount, { customer_id_list: this.custForm.cust_id })
        .then(res => {
          if (res.data.code === 0) {
            this.initData();
            this.$message({ message: '同步成功', type: 'success' });
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(() => { })
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.resetForm('custForm')
        this.$router.go(-1)
        this.$message({
          type: 'info',
          message: '已取消新增!'
        });
      }).catch(() => { })
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 更换属性code
    custAttrChange(val) {
      for (let i = 0; i < this.custAttrList.length; i++) {
        if (val === this.custAttrList[i].param1) {
          this.custForm.cust_attr_code = this.custAttrList[i].param2
          if(this.custForm.cust_attr_code=="f"){
            this.custPidInputFlag=true
          }else{
            this.custPidInputFlag=false
          }
        }
      }
    },
    // 获取客户属性
    getCustAttrList() {
      get(optnAPI.getOptnByPermId, { perm_id: 10006 })
        .then((res) => {
          if (res.data.code === 0) {
            this.custAttrList = res.data.data.form.optn_cntt_list
          }
        }).catch(() => { })
    },
    // 获取客户
    getCustList() {
      get(custAPI.getAllCustsV1)
        .then((res) => {
          if (res.data.code === 0) {
            this.custList = res.data.data.list
          }
        }).catch(() => { })
    },
    // 获取国家
    getContry() {
      get(optnAPI.getOptnByPermId, { perm_id: 10003 })
        .then((res) => {
          if (res.data.code === 0) {
            this.custCountryList = res.data.data.form.optn_cntt_list
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        })
    },
    // 获取付款方式
    getCustPayway() {
      get(optnAPI.getOptnByPermId, { perm_id: 10013 })
        .then((res) => {
          if (res.data.code === 0) {
            this.custPaywayList = res.data.data.form.optn_cntt_list
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        })
    },
    // 获取价格条款
    getCustPaylway() {
      get(optnAPI.getOptnByPermId, { perm_id: 10014 })
        .then((res) => {
          if (res.data.code === 0) {
            this.custPaylwayList = res.data.data.form.optn_cntt_list
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        })
    },
    // 获取币种
    getCustCurrency() {
      get(optnAPI.getOptnByPermId, { perm_id: 10015 })
        .then((res) => {
          if (res.data.code === 0) {
            this.custCurrencyList = res.data.data.form.optn_cntt_list
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        })
    },
    // 获取贸易方式
    getCustTradeway() {
      get(optnAPI.getOptnByPermId, { perm_id: 10016 })
        .then((res) => {
          if (res.data.code === 0) {
            this.custTradewayList = res.data.data.form.optn_cntt_list
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        })
    },
    // 获取运输方式
    getCustShipway() {
      get(optnAPI.getOptnByPermId, { perm_id: 10017 })
        .then((res) => {
          if (res.data.code === 0) {
            this.custShipwayList = res.data.data.form.optn_cntt_list
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        })
    },
    // 获取运输方式
    getCustDport() {
      get(optnAPI.getOptnByPermId, { perm_id: 10018 })
        .then((res) => {
          if (res.data.code === 0) {
            this.custDportList = res.data.data.form.optn_cntt_list
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        })
    },
    // 指定外销员list
    getStffUser() {
      get(stffAPI.getAllStffsV1).then(res => {
        if (res.data.code === 0) {
          this.stffList = res.data.data
          return
        }
        this.$message.error(res.data.msg)
      }).catch(res => {
        this.$message.error(res.data.msg)
      })
    },
    // 获取公司抬头
    getCpttUser() {
      get(cpttAPI.getAllCpttsV1).then(res => {
        if (res.data.code === 0) {
          this.cpttList = res.data.data
          return
        }
        this.$message.error(res.data.msg)
      }).catch(res => {
        this.$message.error(res.data.msg)
      })
    },
    // 改变刷新按钮class
    switchMargin(){
      if(this.custForm.cust_fcp_flag !== 1 && this.custForm.status == 2){
        return "vd_export1";
      }else if(this.custForm.cust_fcp_flag == 1){
        return "vd_export1";
      }else{
        return "vd_export";
      }
    },
    // 获取表单信息
    getcustInfo() {
      // const props = JSON.parse(this.$UrlEncode.decode(this.$route.query.key))
      // this.custForm.cust_id = props.form_id;
      this.custForm.cust_id = this.$route.query.form_id;
      get(custAPI.getCustById, { cust_id: this.custForm.cust_id })
        .then(res => {
          if (res.data.code === 0) {
            this.custForm = res.data.data.form;
            this.btn = res.data.data.btn;
            this.stffForm.stff_name = this.custForm.stff_name;
            this.stffForm.dept_name = this.custForm.dept_name;
            this.stffForm.dept_team_name = this.custForm.dept_team_name;
            this.stffForm.user_id = this.custForm.user_id;
            this.stffForm.dept_id = this.custForm.dept_id;
            this.stffForm.stff_id = this.custForm.stff_id;
            this.custForm.cust_loan = this.helper.reservedDigits(this.custForm.cust_loan)
            this.cust_pid_name=this.custForm.cust_pid_name
            // if(this.custForm.cust_creday === 0){
            //   this.custForm.cust_creday = null
            // }
            if(this.custForm.cust_attr=="最终客户"){
              this.custPidInputFlag=true
            }
            setTimeout(() => {
              this.loadingFlag = false
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        })
    },
  }
}
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}

.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}

.avatar {
  width: 136px;
  height: 136px;
  display: block;
}

.vd_bortop {
  border-top: #DCDFE6 solid 1px;
  padding-top: 20px;
}

.vd_mrt1 {
  margin-top: 1px;
}

.vd_edhea {
  position: relative;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}
.vd_export1 {
  position: absolute;
  top: 0;
  left: 60px;
}

.vd_export2 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  // position: absolute;
  // top: 0;
  // right: 120px;
}
.vd_export3 {
  position: absolute;
  top: 0;
  left: 140px;
}
::v-deep .el-input__count{
	background-color: #fff0;
}
</style>
